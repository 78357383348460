
@-webkit-keyframes octocat-wave {

    0%,
    to {
        transform: rotate(0)
    }

    20%,
    60% {
        transform: rotate(-25deg)
    }

    40%,
    80% {
        transform: rotate(10deg)
    }
}

@keyframes octocat-wave {

    0%,
    to {
        transform: rotate(0)
    }

    20%,
    60% {
        transform: rotate(-25deg)
    }

    40%,
    80% {
        transform: rotate(10deg)
    }
}

.triangle-black {
    border-top: 20px solid black;
    border-left: 50vw solid transparent;
    border-right: 50vw solid transparent;
    position: absolute;
    z-index: 40;
}

.triangle-beige {
    border-top: 20px solid #f0efe5;
    border-left: 50vw solid transparent;
    border-right: 50vw solid transparent;
}

#skiptocontent {
	height: 0;
}

#skiptocontent a {
	padding: 6px;
	position: relative;
	top: -40px;
	left: 50%;
	color: white;
    border: 1px solid white;
    border-radius: 8px;
	background: #BF1722;
	-webkit-transition: top 1s ease-out;
    transition: top 1s ease-out;
    z-index: 100;
}

#skiptocontent a:focus {
	position: relative;
	left: 50%;
	top: 0px;
	outline-color: transparent;	
	-webkit-transition: top .1s ease-in;
    transition: top .1s ease-in;
}

@media (prefers-reduced-motion: reduce) {
	#skiptocontent a {
		transition-duration: 0.001ms !important;
	}
}

/* Flying focus */

#flying-focus {
	position: absolute;
	margin: 0;
	background: transparent;
	-webkit-transition-property: left, top, width, height, opacity;
	transition-property: left, top, width, height, opacity;
	-webkit-transition-timing-function: cubic-bezier(0, 0.2, 0, 1);
	transition-timing-function: cubic-bezier(0, 0.2, 0, 1);
	visibility: hidden;
	pointer-events: none;
	box-shadow: 0 0 2px 3px #78aeda, 0 0 2px #78aeda inset; border-radius: 2px;
}
#flying-focus.flying-focus_visible {
	visibility: visible;
	z-index: 9999;
}
.flying-focus_target {
	outline: none !important; /* Doesn't work in Firefox :( */
}
/* http://stackoverflow.com/questions/71074/how-to-remove-firefoxs-dotted-outline-on-buttons-as-well-as-links/199319 */
.flying-focus_target::-moz-focus-inner {
	border: 0 !important;
}
/* Replace it with @supports rule when browsers catch up */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
	#flying-focus {
		box-shadow: none;
		outline: 5px auto -webkit-focus-ring-color;
		outline-offset: -3px;
	}
}

@media (prefers-reduced-motion: reduce) {
	#flying-focus {
		transition-duration: 0.001ms !important;
	}
}

.init * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}


a {
    color:currentColor;
    text-decoration: underline;
}

a:hover{
background-color: rgba(255, 255, 255, 0.877);
color: #111;
border-top: 1px solid white;
border-bottom: 1px solid white;
border-radius: 3px;
}

img { max-width:100%; height: auto; }