@keyframes octocat-wave {
  0%, to {
    transform: rotate(0);
  }

  20%, 60% {
    transform: rotate(-25deg);
  }

  40%, 80% {
    transform: rotate(10deg);
  }
}

.triangle-black {
  z-index: 40;
  border-top: 20px solid #000;
  border-left: 50vw solid #0000;
  border-right: 50vw solid #0000;
  position: absolute;
}

.triangle-beige {
  border-top: 20px solid #f0efe5;
  border-left: 50vw solid #0000;
  border-right: 50vw solid #0000;
}

#skiptocontent {
  height: 0;
}

#skiptocontent a {
  color: #fff;
  z-index: 100;
  background: #bf1722;
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 6px;
  transition: top 1s ease-out;
  position: relative;
  top: -40px;
  left: 50%;
}

#skiptocontent a:focus {
  outline-color: #0000;
  transition: top .1s ease-in;
  position: relative;
  top: 0;
  left: 50%;
}

@media (prefers-reduced-motion: reduce) {
  #skiptocontent a {
    transition-duration: .001ms !important;
  }
}

#flying-focus {
  visibility: hidden;
  pointer-events: none;
  background: none;
  border-radius: 2px;
  margin: 0;
  transition-property: left, top, width, height, opacity;
  transition-timing-function: cubic-bezier(0, .2, 0, 1);
  position: absolute;
  box-shadow: 0 0 2px 3px #78aeda, inset 0 0 2px #78aeda;
}

#flying-focus.flying-focus_visible {
  visibility: visible;
  z-index: 9999;
}

.flying-focus_target {
  outline: none !important;
}

.flying-focus_target::-moz-focus-inner {
  border: 0 !important;
}

@media screen and (-webkit-device-pixel-ratio >= 0) {
  #flying-focus {
    box-shadow: none;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -3px;
  }
}

@media (prefers-reduced-motion: reduce) {
  #flying-focus {
    transition-duration: .001ms !important;
  }
}

.init * {
  -o-transition: none !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
}

a {
  color: currentColor;
  text-decoration: underline;
}

a:hover {
  color: #111;
  background-color: #ffffffe0;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-radius: 3px;
}

img {
  max-width: 100%;
  height: auto;
}

/*# sourceMappingURL=index-fr.a5ca2d6b.css.map */
